export default class LayoutSwitcher {
    constructor() {
        this.layoutSwitches = [
            ...document.querySelectorAll(".js--layout-switch"),
        ];

        this.postsContainer = document.querySelector(".container--products");

        if (this.layoutSwitches.length && this.postsContainer) {
            this.events();
        }
    }

    events() {
        this.layoutSwitches.forEach((layoutSwitch) =>
            layoutSwitch.addEventListener(
                "click",
                this.changeListLayout.bind(this)
            )
        );
    }

    changeListLayout(ev) {
        const clickedSwitch = ev.currentTarget;
        const postsList = this.postsContainer.querySelector(".js--posts-list");

        if (!clickedSwitch) return;

        this.layoutSwitches.forEach((layoutSwitch) => {
            layoutSwitch.classList.remove("is-selected");
            layoutSwitch.setAttribute("aria-pressed", false);
        });

        clickedSwitch.classList.add("is-selected");
        clickedSwitch.setAttribute("aria-pressed", true);

        if (!postsList) return;

        postsList.classList.remove("archive-list", "archive-grid");
        postsList.classList.add(clickedSwitch.dataset.layout);
    }
}
