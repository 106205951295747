/* eslint-disable no-undef */
export default class RefreshPageFragments {
    constructor() {
        this.events();
    }

    events() {
        this.initRefreshPageFragments();
    }

    initRefreshPageFragments() {
        /* Refresh page fragments */
        fetch(`${__jsVars.url.ajax}?action=ic-refresh-fragments`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })
            .then((resp) => resp.json())
            .then((res) => {
                if (res.success) {
                    for (const selector in res.data.fragments) {
                        const elementToReplace =
                            document.querySelector(selector);

                        if (elementToReplace)
                            elementToReplace.replaceWith(
                                res.data.fragments[selector]
                            );
                    }

                    for (const selector in res.data.values) {
                        const elementToUpdate =
                            document.querySelector(selector);

                        if (elementToUpdate)
                            elementToUpdate.value = res.data.values[selector];
                    }

                    for (const selector in res.data.vars) {
                        __jsVars[selector] = res.data.vars[selector];
                    }

                    __jsVars.favorites = res.data.favorites;

                    document.body.dispatchEvent(
                        new CustomEvent(
                            "ic-theme-complete-load-fragments",
                            res.data
                        )
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
}
