export default class HoursPopup {
    constructor() {
        this.body = document.body;
        this.hoursPopup = document.querySelector(".hours-popup");

        if (this.hoursPopup) {
            this.popupClose = this.hoursPopup.querySelector(
                ".hours-popup__close"
            );
            this.popupDescLink = this.hoursPopup.querySelector(
                ".hours-popup__description a"
            );

            this.events();
        }
    }

    events() {
        this.showHoursPopupOnPageLoad();

        /* Close popup & save setting to localStorage */
        if (this.popupClose) {
            this.popupClose.addEventListener(
                "click",
                this.closeHoursPopup.bind(this)
            );
        }

        if (this.popupDescLink) {
            this.popupDescLink.addEventListener(
                "click",
                this.closeHoursPopup.bind(this)
            );
        }
    }

    showHoursPopupOnPageLoad() {
        const visited = this.readCookie("visited");

        /* Show popup only if exists in DOM & was not previously shown to user within 30 days */
        if (this.hoursPopup && (!visited || visited !== "true")) {
            if (!this.body.classList.contains("page-template-page-catalog")) {
                this.hoursPopup.classList.add("is-animating-in", "is-visible");

                this.hoursPopup.addEventListener("animationend", (ev) => {
                    this.hoursPopup.classList.remove("is-animating-in");
                });
            }
        }
    }

    closeHoursPopup(ev) {
        this.createCookie("visited", "true", 7);
        this.hoursPopup.classList.add("is-animating-out", "is-visible");

        this.hoursPopup.addEventListener("animationend", (ev) => {
            this.hoursPopup.classList.remove("is-animating-out", "is-visible");
        });
    }

    /* Save cookies settings */
    createCookie(name, value, days) {
        let expires;

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }

        document.cookie = name + "=" + value + expires + "; path=/";
    }

    readCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(";");

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) === " ") c = c.substring(1, c.length);

            if (c.indexOf(nameEQ) === 0)
                return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    // eslint-disable-next-line no-unused-vars
    eraseCookie(name) {
        this.createCookie(name, "", -1);
    }
}
