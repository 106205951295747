/* eslint-disable no-undef */
export default class Favourites {
    constructor() {
        this.favouritePopupOpenBtn = document.querySelector(
            ".js--favorite-popup",
        );
        this.favouritePopupCloseBtn = document.querySelector(
            ".js--fav__popup-close",
        );

        this.favouritePopup = document.querySelector(".js--fav__popup");

        this.email = document.querySelector(
            ".js--favorite-send input[type=email]",
        );
        this.checkbox = document.querySelector(
            ".js--favorite-send input[type=checkbox]",
        );
        this.submitButton = document.querySelector(
            ".js--favorite-send input[type=submit]",
        );
        this.sendFavouritesForm = document.querySelector(".js--favorite-send");
        this.sendFavouritesFormOk = document.querySelector(
            ".js--favorite-send-ok-message",
        );

        this.removeAllFavourites = document.querySelector(".js--ra-overlay");
        this.removeAllFavouritesCancel =
            document.querySelector(".js--ra-cancel");

        this.addFavourites = [];

        this.events();
    }

    events() {
        if (
            this.favouritePopupOpenBtn &&
            this.favouritePopupCloseBtn &&
            this.favouritePopup
        ) {
            this.favouritePopupOpenBtn.addEventListener(
                "click",
                this.openPopup.bind(this),
            );
            this.favouritePopupCloseBtn.addEventListener(
                "click",
                this.closePopup.bind(this),
            );
        }

        if (this.email && this.checkbox && this.submitButton) {
            this.checkbox.addEventListener(
                "change",
                this.validateForm.bind(this),
            );
            this.email.addEventListener("keyup", this.validateForm.bind(this));
            this.email.addEventListener("blur", this.validateForm.bind(this));
        }

        if (this.removeAllFavourites && this.removeAllFavouritesCancel) {
            this.removeAllFavourites.addEventListener(
                "click",
                this.activateRemoveAll.bind(this),
            );
            this.removeAllFavouritesCancel.addEventListener(
                "click",
                this.cancelRemoveAll.bind(this),
            );
        }

        document.body.addEventListener(
            "ic-theme-complete-load-fragments",
            this.refreshFavourites.bind(this),
        );

        if (this.sendFavouritesForm) {
            this.sendFavouritesForm.addEventListener(
                "submit",
                this.sendFavouritesData.bind(this),
            );
        }
    }

    openPopup() {
        this.favouritePopup.classList.add("open");
        document.body.classList.add("overflow-hidden");
    }

    closePopup() {
        this.favouritePopup.classList.remove("open");
        document.body.classList.remove("overflow-hidden");
    }

    validateForm() {
        // eslint-disable-next-line prefer-regex-literals
        const patt = new RegExp(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );

        if (patt.test(this.email.value) && this.checkbox.checked) {
            this.submitButton.classList.remove("disabled");
            this.submitButton.removeAttribute("disabled");
        } else {
            this.submitButton.classList.add("disabled");
            this.submitButton.setAttribute("disabled", true);
        }
    }

    activateRemoveAll() {
        this.removeAllFavourites.classList.add("active");
    }

    cancelRemoveAll() {
        this.removeAllFavourites.classList.remove("active");
    }

    refreshFavourites() {
        if (!__jsVars.favorites) return;

        for (const postId in __jsVars.favorites) {
            const favourite = document.querySelector(
                `.js--add-favorite[data-post_id="${postId}"]`,
            );

            if (favourite) favourite.classList.add("is-fav");
        }

        const currentAddFavourites = this.addFavourites;

        this.addFavourites = [
            ...document.querySelectorAll(".js--add-favorite"),
        ];

        const addFavouritesWithoutClickEvent = this.addFavourites.filter(
            (val) => !currentAddFavourites.includes(val),
        );

        if (addFavouritesWithoutClickEvent.length) {
            addFavouritesWithoutClickEvent.forEach((fav) =>
                fav.addEventListener("click", this.addToFavourites.bind(this)),
            );
        }
    }

    addToFavourites(ev) {
        ev.preventDefault();
        const clickedFav = ev.currentTarget;

        if (!clickedFav) return;
        const postId = parseInt(clickedFav.dataset.post_id, 10);

        if (!postId) return;
        const postData = {
            action: "ic-favorite",
            post_id: postId,
        };

        fetch(__jsVars.url.ajax, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: new URLSearchParams(postData),
        })
            .then((resp) => resp.json())
            .then((res) => {
                if (res.success) {
                    const addFavouritesToUpdate = this.addFavourites.filter(
                        (fav) => parseInt(fav.dataset.post_id, 10) === postId,
                    );

                    if (addFavouritesToUpdate.length) {
                        addFavouritesToUpdate.forEach((fav) =>
                            fav.classList.toggle("is-fav"),
                        );
                    }
                } else {
                    alert(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    sendFavouritesData(ev) {
        ev.preventDefault();

        const formData = new FormData(this.sendFavouritesForm);

        fetch(__jsVars.url.ajax, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: new URLSearchParams(formData),
        })
            .then((resp) => resp.json())
            .then((res) => {
                if (res.success) {
                    this.sendFavouritesForm.style.display = "none";
                    this.sendFavouritesFormOk.style.display = "block";
                } else {
                    alert(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
}
