export default class Tabs {
    constructor() {
        this.promoTabsWrap = document.querySelector(".js--promo-tabs-wrap");
        this.servicesTabsWrap = document.querySelector(
            ".js--services-tabs-wrap"
        );

        if (this.promoTabsWrap) {
            this.promoTabs = [
                ...this.promoTabsWrap.querySelectorAll(".js--tab"),
            ];

            this.promoTabPanels = [
                ...this.promoTabsWrap.querySelectorAll(".js--tabpanel"),
            ];
        }

        if (this.servicesTabsWrap) {
            this.servicesTabs = [
                ...this.servicesTabsWrap.querySelectorAll(".js--services-tab"),
            ];

            this.servicesTabPanels = [
                ...this.servicesTabsWrap.querySelectorAll(
                    ".js--services-tabpanel"
                ),
            ];
        }

        this.events();
    }

    events() {
        if (
            this.promoTabsWrap &&
            this.promoTabs.length &&
            this.promoTabPanels.length
        ) {
            this.promoTabs.forEach((tab) =>
                tab.addEventListener(
                    "click",
                    this.changePanelToUserSelected.bind(
                        this,
                        this.promoTabs,
                        this.promoTabPanels,
                        false
                    )
                )
            );
        }

        if (
            this.servicesTabsWrap &&
            this.servicesTabs.length &&
            this.servicesTabPanels.length
        ) {
            this.initTabsWithHrefs(this.servicesTabs, this.servicesTabPanels);

            this.servicesTabs.forEach((tab) =>
                tab.addEventListener(
                    "click",
                    this.changePanelToUserSelected.bind(
                        this,
                        this.servicesTabs,
                        this.servicesTabPanels,
                        true
                    )
                )
            );
        }
    }

    changePanelToUserSelected(tabs, panels, withHref, ev) {
        const clickedTab = ev.currentTarget;

        this.switchTabPanel(tabs, panels, clickedTab, withHref);
    }

    initTabsWithHrefs(tabs, panels) {
        // get first element from array
        let tabToSelect = tabs.find((e) => typeof e !== "undefined");

        // check for specific hash
        const hashOptions = [
            "#montaz",
            "#transport",
            "#asystent-klienta",
            "#architekt-wnetrz",
        ];

        if (hashOptions.includes(window.location.hash)) {
            tabToSelect = tabs.find(
                (tab) => tab.dataset.href === window.location.hash
            );
        }

        this.switchTabPanel(tabs, panels, tabToSelect);
    }

    switchTabPanel(tabs, panels, selectedTab, withHref) {
        const { id } = selectedTab;

        if (withHref) {
            window.location.hash = selectedTab.dataset.href;
        }

        // hide all tab panels
        panels.forEach((panel) => {
            panel.classList.remove("active-tab");
        });

        // mark all tabs as unselected
        tabs.forEach((tab) => {
            tab.setAttribute("aria-selected", false);
            tab.classList.remove("active-tab");
        });

        // mark the clicked tab as selected
        selectedTab.setAttribute("aria-selected", true);
        selectedTab.classList.add("active-tab");

        // find the associated tabPanel and show it!
        const tabPanel = panels.find(
            (panel) => panel.getAttribute("aria-labelledby") === id
        );

        tabPanel.classList.add("active-tab");
    }
}
