import { pageWidth } from "../../functions/windowSizeFunctions";

export default class Navigation {
    constructor() {
        this.hamburger = document.querySelector(".js--header__ham");
        this.navWrapper = document.querySelector(".nav-wraper");
        this.headerWrappers = [...document.querySelectorAll(".header-wraper")];
        this.menuItems = [...document.querySelectorAll(".menu-item")];

        this.events();
    }

    events() {
        if (this.hamburger && this.navWrapper) {
            this.hamburger.addEventListener(
                "click",
                this.toggleNavbar.bind(this)
            );
        }

        if (this.menuItems.length) {
            this.menuItems.forEach((item) => {
                item.addEventListener(
                    "mouseenter",
                    this.showSubmenu.bind(this)
                );
                item.addEventListener(
                    "mouseleave",
                    this.hideSubmenu.bind(this)
                );
            });
        }
    }

    toggleNavbar() {
        this.hamburger.classList.toggle("is-active");
        this.navWrapper.classList.toggle("slide-down");
    }

    showSubmenu(ev) {
        const selectedMenuItem = ev.currentTarget;

        if (!selectedMenuItem) return;
        const dropdown = selectedMenuItem.querySelector(".dropdown");

        if (!dropdown) return;
        if (!this.headerWrappers.length) return;

        this.addSubmenuArrow(selectedMenuItem);

        if (pageWidth() > 992) {
            this.headerWrappers.forEach(
                (wrapper) => (wrapper.style.zIndex = 1095)
            );
            document.body.classList.add("dropdown-open");
        }
    }

    hideSubmenu(ev) {
        const currentMenuItem = ev.currentTarget;

        if (!currentMenuItem) return;
        const dropdown = currentMenuItem.querySelector(".dropdown");

        if (!dropdown) return;
        if (!this.headerWrappers.length) return;

        this.addSubmenuArrow(currentMenuItem);

        if (pageWidth() > 992) {
            this.headerWrappers.forEach((wrapper) =>
                wrapper.removeAttribute("style")
            );
            document.body.classList.remove("dropdown-open");
        }
    }

    addSubmenuArrow(menuItem) {
        const link = menuItem.querySelector("a");

        const ll = link.offsetLeft;
        const ww = link.getBoundingClientRect().width;
        const poo = ll + ww / 2 - 14;

        if (!menuItem.classList.contains("small-dropdown")) {
            const arrow = menuItem.querySelector(".dropdown .arrow");

            if (!arrow) return;
            arrow.style.left = `${poo}px`;
        }
    }
}
