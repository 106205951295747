import { pageHeight } from "../../functions/windowSizeFunctions";

export default class Popup {
    constructor() {
        this.popupId = null;
        this.popupScrollClose = false;
        this.popup = document.querySelector(".js--popup");
        this.popupCloseBtn = document.querySelector(".js--popup-close");

        this.email = document.querySelector(
            ".js--popup .popup__form input[type=email]"
        );
        this.name = document.querySelector(
            ".js--popup .popup__form input[type=text]"
        );
        this.checkboxList = [
            ...document.querySelectorAll(".js--popup .popup__list-element"),
        ];
        this.checkboxAcceptance = document.querySelector(
            ".js--popup .popup__acceptance-input"
        );
        this.submitButton = document.querySelector(
            ".js--popup .popup__form input[type=submit]"
        );

        if (this.popup) {
            this.events();
        }
    }

    events() {
        this.showPopup();

        if (this.popupCloseBtn) {
            this.popupCloseBtn.addEventListener(
                "click",
                this.closePopup.bind(this)
            );
        }

        if (
            this.email &&
            this.name &&
            this.checkboxList.length &&
            this.checkboxAcceptance
        ) {
            this.checkboxAcceptance.addEventListener(
                "change",
                this.validateForm.bind(this)
            );

            this.checkboxList.forEach((checkbox) =>
                checkbox.addEventListener(
                    "change",
                    this.validateForm.bind(this)
                )
            );

            this.email.addEventListener("keyup", this.validateForm.bind(this));
            this.email.addEventListener("blur", this.validateForm.bind(this));
            this.name.addEventListener("keyup", this.validateForm.bind(this));
            this.name.addEventListener("blur", this.validateForm.bind(this));
        }
    }

    showPopup() {
        const popupOptions = JSON.parse(this.popup.dataset.options);

        this.popupId = popupOptions?.popup_id;
        const visibilityType = popupOptions?.visibility_type ?? "";
        const visibilityTimeout = popupOptions?.visibility_timeout ?? 1;
        const visibilityScroll = popupOptions?.visibility_scroll ?? 0;
        const capping = popupOptions?.capping ?? 1;

        if (!this.popupId) return;

        if (!localStorage.getItem(this.popupId)) {
            localStorage.setItem(this.popupId, 0);
        }

        if (parseInt(localStorage.getItem(this.popupId), 10) >= capping) return;

        switch (visibilityType) {
            case "loaded":
                this.popup.classList.add("show-popup");
                break;
            case "timeout":
                setTimeout(() => {
                    this.popup.classList.add("show-popup");
                }, visibilityTimeout * 1000);
                break;
            case "scroll":
                window.addEventListener("scroll", () => {
                    const pageDocument =
                        document.documentElement.getBoundingClientRect();

                    const pageDocumentHeight = pageDocument.height;
                    const pageDocumentTop = Math.abs(pageDocument.top);

                    const scrollPercent =
                        (pageDocumentTop /
                            (pageDocumentHeight - pageHeight())) *
                        100;
                    if (
                        scrollPercent > visibilityScroll &&
                        !this.popupScrollClose
                    ) {
                        this.popup.classList.add("show-popup");
                    }
                });
                break;
        }
    }

    closePopup() {
        this.popup.classList.remove("show-popup");
        this.popupScrollClose = true;
        localStorage.setItem(
            this.popupId,
            parseInt(localStorage.getItem(this.popupId), 10) + 1
        );
    }

    validateForm(ev) {
        // eslint-disable-next-line prefer-regex-literals
        const patt = new RegExp(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if (!this.submitButton) return;

        const checkedCheckboxes = this.checkboxList.filter(
            (checkbox) => checkbox.checked
        );

        if (
            patt.test(this.email.value) &&
            this.checkboxAcceptance.checked &&
            this.name.value.length > 1 &&
            checkedCheckboxes.length
        ) {
            this.submitButton.classList.remove("disabled");
            this.submitButton.removeAttribute("disabled");
        } else {
            this.submitButton.classList.add("disabled");
            this.submitButton.setAttribute("disabled", "true");
        }
    }
}
