/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import $ from "jquery";
import selectric from "selectric";

export default class CustomSelect {
    constructor() {
        this.sortedSelects = [
            ...document.querySelectorAll(".js--sorted-select"),
        ];
        this.customSelects = [
            ...document.querySelectorAll(".js--custom-select"),
        ];

        this.events();
    }

    events() {
        if (this.sortedSelects.length) {
            /* select with added letters */
            this.initSelects(this.sortedSelects, true);
        }

        if (this.customSelects.length) {
            /* regular select(in news and advices) */
            this.initSelects(this.customSelects, false);
        }
    }

    initSelects(selectsList, useAlphabet) {
        selectsList.forEach((selectList) => {
            const selectricItem = $(selectList).selectric({
                inheritOriginalWidth: false,
                disableOnMobile: false,
                nativeOnMobile: false,
                arrowButtonMarkup: '<span class="custom-select-arrow"></span>',
                onInit: function () {
                    const selectedItems = [
                        ...document.querySelectorAll(
                            ".selectric-items .selected"
                        ),
                    ];

                    if (selectedItems.length) {
                        selectedItems.forEach((selectedItem) => {
                            if (
                                parseInt(selectedItem.dataset.index, 10) === 0
                            ) {
                                const selectWrapper =
                                    selectedItem.closest(".selectric-wrapper");

                                selectWrapper.classList.add("first-selected");
                            }
                        });
                    }
                },
            });

            if (useAlphabet) {
                this.addLetterLabelToOptionData(selectList);
            }
        });
    }

    addLetterLabelToOptionData(selectList) {
        const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

        const selectricWrapper = selectList.closest(".selectric-wrapper");

        if (!selectricWrapper) return;

        const selectricListItems = [
            ...selectricWrapper.querySelectorAll(".selectric-items li"),
        ];

        alphabet.forEach((el) => {
            if (!selectricListItems.length) return;

            for (const item of selectricListItems) {
                if (
                    item.innerText.charAt(0).toLocaleLowerCase() === el &&
                    parseInt(item.dataset.index, 10) !== 0
                ) {
                    item.insertAdjacentHTML(
                        "beforeend",
                        `<span class="select-letter">${el.toUpperCase()}</span>`
                    );

                    break;
                }
            }
        });
    }
}
