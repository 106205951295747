import $ from "jquery";

export default class OpenCategoryUrl {
    constructor() {
        this.openCategoryUrlTrigger = [
            ...document.querySelectorAll(".js--open-window"),
        ];

        this.events();
    }

    events() {
        if (this.openCategoryUrlTrigger.length) {
            this.openCategoryUrlTrigger.forEach((trigger) =>
                $(trigger).on("selectric-change", (ev, element, selectric) => {
                    this.openCategoryUrl(ev, element, selectric);
                })
            );
        }
    }

    /* Open Category url */
    openCategoryUrl(ev, element, selectric) {
        const url = element?.value;

        if (!url) return;
        window.open(url, "_top");
    }
}
