/* eslint-disable no-undef */
import { isVisible } from "../../functions/helpers";

export default class NewsletterSignup {
    constructor() {
        this.newsletterForms = [
            ...document.querySelectorAll(".js--newsletter-form"),
        ];

        if (this.newsletterForms.length) {
            this.events();
        }
    }

    events() {
        this.newsletterForms.forEach((nForm) =>
            nForm.addEventListener(
                "submit",
                this.submitNewsletterForm.bind(this, nForm)
            )
        );
    }

    submitNewsletterForm(nForm, ev) {
        ev.preventDefault();

        const formData = new FormData(nForm);

        fetch(__jsVars.url.ajax, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: new URLSearchParams(formData),
        })
            .then((resp) => resp.json())
            .then((res) => {
                if (res.success) {
                    /* form in popup */
                    const newsletterPopup = nForm.closest(
                        ".js--popup.popup--newsletter"
                    );

                    if (newsletterPopup && isVisible(newsletterPopup)) {
                        const popupConent =
                            newsletterPopup.querySelector(".js--popup-content");
                        const popupMessage =
                            newsletterPopup.querySelector(".js--popup-message");
                        if (popupConent && popupMessage) {
                            popupMessage.innerHTML = `<p>${res.data.message}</p>`;
                            popupConent.style.display = "none";
                            popupMessage.style.display = "block";
                        }
                    } else {
                        /* form in footer */
                        const hideOnSubmitWrapper = nForm.querySelector(
                            ".js--footer-newsletter-hide-on-submit"
                        );
                        const showOnSubmitWrapper = nForm.querySelector(
                            ".js--footer-newsletter-show-on-submit"
                        );

                        if (showOnSubmitWrapper && hideOnSubmitWrapper) {
                            showOnSubmitWrapper.innerHTML = `<p>${res.data.message}</p>`;
                            hideOnSubmitWrapper.style.display = "none";
                            showOnSubmitWrapper.style.display = "block";
                        }
                    }
                } else {
                    /* form in popup */
                    const newsletterPopup = nForm.closest(
                        ".js--popup.popup--newsletter"
                    );

                    if (newsletterPopup && isVisible(newsletterPopup)) {
                        const popupConent =
                            newsletterPopup.querySelector(".js--popup-content");
                        const popupMessage =
                            newsletterPopup.querySelector(".js--popup-message");
                        if (popupConent && popupMessage) {
                            popupMessage.innerHTML = `<p>${res.data.message}</p>`;
                            popupConent.style.display = "none";
                            popupMessage.style.display = "block";
                        }
                    } else {
                        /* form in footer */
                        const hideOnSubmitWrapper = nForm.querySelector(
                            ".js--footer-newsletter-hide-on-submit"
                        );
                        const showOnSubmitWrapper = nForm.querySelector(
                            ".js--footer-newsletter-show-on-submit"
                        );

                        if (showOnSubmitWrapper && hideOnSubmitWrapper) {
                            showOnSubmitWrapper.innerHTML = `<p>${res.data.message}</p>`;
                            hideOnSubmitWrapper.style.display = "none";
                            showOnSubmitWrapper.style.display = "block";
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
}
