export default class LoadMorePosts {
    constructor() {
        this.loadMoreBtn = document.querySelector(".js--load-more-posts");
        this.postsList = document.querySelector(".js--posts-list");

        this.events();
    }

    events() {
        if (this.postsList && this.loadMoreBtn) {
            this.startWithObserver(this.loadMoreBtn, {
                rootMargin: "0px 0px 0px 0px",
            });

            this.loadMoreBtn.addEventListener(
                "click",
                this.loadMorePosts.bind(this)
            );
        }
    }

    startWithObserver(observedElement, observedElementOptions) {
        const current = this;
        const countersObserverOptions = observedElementOptions;
        const countersObserver = new IntersectionObserver(function (
            entries,
            observedElement
        ) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    current.loadMorePosts();
                }
            });
        },
        countersObserverOptions);

        countersObserver.observe(observedElement);
    }

    loadMorePosts() {
        if (this.loadMoreBtn.classList.contains("disabled")) {
            return;
        }

        // eslint-disable-next-line no-undef
        const myUrl = new URL(__jsVars.url.ajax);
        const btnData = { ...this.loadMoreBtn.dataset };

        for (const property in btnData) {
            myUrl.searchParams.append(property, btnData[property]);
        }

        this.loadMoreBtn.classList.add("disabled", "preloader");

        fetch(myUrl.href, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })
            .then((resp) => resp.json())
            .then((res) => {
                if (res.success) {
                    this.loadMoreBtn.dataset.paged = res.data.paged;

                    this.postsList.insertAdjacentHTML(
                        "beforeend",
                        res.data.html
                    );

                    document.body.dispatchEvent(
                        new CustomEvent("ic-theme-complete-load-fragments", {})
                    );

                    if (
                        res.data.paged ===
                        parseInt(
                            this.loadMoreBtn.getAttribute("data-max_num_pages"),
                            10
                        )
                    ) {
                        const loadMoreContainer = document.querySelector(
                            ".js--load-more-container"
                        );

                        if (loadMoreContainer) loadMoreContainer.remove();
                    }
                }

                if (this.loadMoreBtn)
                    this.loadMoreBtn.classList.remove("disabled", "preloader");
            })
            .catch((err) => {
                if (this.loadMoreBtn)
                    this.loadMoreBtn.classList.remove("disabled", "preloader");

                console.log(err);
            });
    }
}
