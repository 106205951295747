/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import $ from "jquery";
import owlCarousel from "owl.carousel";
import magnificPopup from "magnific-popup";

export default class Sliders {
    constructor() {
        this.events();
    }

    events() {
        this.initializeFrontPageHeroSlider();
        this.initializeCategoriesSlider();
        this.initializePromotionSlider();
        this.initializeProductSlider();
        this.initializeAdviceSlider();
        this.initializeShopsSlider();
        this.initializeSingleCptSlider();
        this.initializeSinglePostSlider();
        this.initializeContestSlider();
        this.initializeWebinarSliders();
    }

    initializeFrontPageHeroSlider() {
        /**
         * front page hero slider
         */
        let t;
        const heroCarousel = $(".js-fp-hero__slider");

        function goNext() {
            heroCarousel.trigger("next.owl.carousel");
        }

        // set timeout for first slide
        heroCarousel.on("initialized.owl.carousel", function (event) {
            const wait = $(".owl-item.active")
                .find(".fp-hero__slide")
                .data("duration");
            clearTimeout(t);
            t = setTimeout(function () {
                goNext();
            }, wait * 1000);
        });

        // initialize hero slider
        heroCarousel.owlCarousel({
            loop: true,
            items: 1,
            dots: true,
        });

        // set timeout for other slides
        heroCarousel.on("changed.owl.carousel", function (event) {
            const wait = $(".owl-item.active")
                .next()
                .find(".fp-hero__slide")
                .data("duration");
            clearTimeout(t);
            t = setTimeout(function () {
                goNext();
            }, wait * 1000);
        });
    }

    initializeCategoriesSlider() {
        /**
         * categories slider
         */
        let start;
        let noActive = true;
        const owlCategories = $(".js-categories__slider");

        // find active slide index
        if ($(".categories__slide.active").length > 0) {
            start = $(".categories__slide.active").index();
        }

        owlCategories.owlCarousel({
            loop: false,
            dots: false,
            nav: true,
            center: false,
            startPosition: start - 1,
            navText: [
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-left"></use></svg></span>',
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-rigth"></use></svg></span>',
            ],
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 2,
                    stagePadding: 55,
                    nav: false,
                },
                // breakpoint from 480 up
                400: {
                    items: 3,
                    stagePadding: 55,
                    nav: false,
                },
                // breakpoint from 480 up
                576: {
                    items: 4,
                },
                // breakpoint from 480 up
                768: {
                    items: 6,
                },
                // breakpoint from 768 up
                992: {
                    items: 8,
                },
            },
        });

        // check for active element
        $(".categories__slide").each(function () {
            if ($(this).hasClass("active")) {
                noActive = false;
            }
        });

        // if no active category and not home page
        // find fist slide and add active class
        if (noActive && __jsVars.url.home != window.location.href) {
            $(".js-categories__slider .owl-item")
                .first()
                .find(".categories__slide")
                .addClass("active");
        }
    }

    initializePromotionSlider() {
        /**
         * promotion slider
         */
        $(".js-fp-promotion__slider").owlCarousel({
            loop: true,
            items: 1,
            dots: true,
            nav: false,
            autoplay: true,
            autoplayTimeout: 4000,
        });
    }

    initializeProductSlider() {
        /**
         * product slider + product__slider-inner
         */

        // stop propagation for product sliders
        function stopOwlPropagation(element) {
            $(element).on("to.owl.carousel", function (e) {
                e.stopPropagation();
            });
        }

        stopOwlPropagation(".owl-carousel");

        /* main product-slider */
        $(".js--product-slider").owlCarousel({
            loop: false,
            items: 1,
            dots: true,
            nav: false,
        });

        /* product-slider-inner */
        $("div[id^='slider-key-']").owlCarousel({
            loop: false,
            items: 1,
            dots: false,
            nav: true,
            navText: [
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-left"></use></svg></span>',
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-rigth"></use></svg></span>',
            ],
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
        });

        /* slide inner slider on product pin hover */
        $(".js--product-slider .product-slider__marker").on(
            "mouseenter",
            function () {
                const sliderId = $(this)
                    .parents(".product-slider__slide")
                    .find(".product-slider-inner")
                    .attr("id")
                    .slice(-1);
                const parentSlides = $(this)
                    .parents(".product-slider__slide")
                    .find(".product-slider-inner")
                    .find(".owl-item");
                const itemId = $(this).parent().data("id");
                let slideIndex;

                parentSlides.each(function (el) {
                    const id = $(this).children().data("id");

                    if (id == itemId) {
                        slideIndex = el;
                    }
                });
                $(this)
                    .parents(".product-slider__slide")
                    .find(".product-slider-inner")
                    .trigger("to.owl.carousel", [slideIndex, 500, true]);
            }
        );

        // find pins position and set classes
        $(".js--pin").each(function () {
            const top = parseFloat(this.style.bottom);
            const left = parseFloat(this.style.left);

            /* tooltip position */
            if (top > 50) {
                $(this).find(".product-slider__tooltip").addClass("top");
            }
            if (top > 50 && left > 60) {
                $(this).find(".product-slider__tooltip").addClass("right");
            }
            if (top < 50) {
                $(this).find(".product-slider__tooltip").addClass("bottom");
            }
            if (top < 50 && left > 60) {
                $(this).find(".product-slider__tooltip").addClass("right");
            }
        });

        /* jumping markers */
        $(".js--product-slider-inner__button")
            .on("mouseenter", function () {
                // find corresponding pin
                const hoverId = $(this).parent().data("id");
                const pin = $(this)
                    .parents(".product-slider__slide")
                    .find(".product-slider__img")
                    .find(".js--pin[data-id=" + hoverId + "]");
                // add class to marker
                pin.children().addClass("pin-jump");
            })
            .on("mouseleave", function () {
                // find all pins
                const pins = $(this)
                    .parents(".product-slider__slide")
                    .find(".product-slider__img")
                    .find(".js--pin");
                // remove class from markers
                pins.children().removeClass("pin-jump");
            });

        if ($(window).width() < 768) {
            $(".product-slider__marker").on("click", function () {
                const pin = $(this).find(".product-slider__tooltip");
                const pinCloned = pin.clone();
                const mobileWrapper = $(this)
                    .parent()
                    .parent()
                    .find(".product-slider__mobileWrapper");
                const mobileWrapperItems = mobileWrapper.find(
                    ".product-slider__mobileWrapperItems"
                );
                mobileWrapperItems.append(pinCloned);

                if (mobileWrapper.is(":visible")) {
                    mobileWrapper.hide();
                    mobileWrapperItems.empty();
                } else {
                    mobileWrapper.show();
                    mobileWrapperItems.find(".product-slider__tooltip").css({
                        display: "block",
                        position: "relative",
                    });
                }
            });

            $(document).on("click", ".tooltip-close", function (e) {
                e.preventDefault();
                const mobileWrapper = $(this)
                    .parents()
                    .find(".product-slider__mobileWrapper");
                const mobileWrapperItems = mobileWrapper.find(
                    ".product-slider__mobileWrapperItems"
                );

                mobileWrapper.hide();
                mobileWrapperItems.empty();
            });
        }
    }

    initializeAdviceSlider() {
        /**
         * advice slider
         */
        $(".js-fp-advice__slider").owlCarousel({
            loop: true,
            items: 1,
            dots: true,
            nav: false,
            autoplay: true,
            autoplayTimeout: 4000,
        });
    }

    initializeShopsSlider() {
        /**
         * shops slider
         */
        $(".js-fp-shops__slider").owlCarousel({
            loop: true,
            dots: false,
            nav: false,
            autoplay: true,
            autoplayTimeout: 2000,
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 2,
                },
                // breakpoint from 480 up
                400: {
                    items: 3,
                },
                // breakpoint from 480 up
                576: {
                    items: 4,
                },
                // breakpoint from 480 up
                768: {
                    items: 5,
                },
                // breakpoint from 768 up
                992: {
                    items: 6,
                },
            },
        });
    }

    initializeSingleCptSlider() {
        /**
         * single page slider (product, promotion, shop)
         */
        const singlePageSlider = $(".js--sp-slider");
        const self = this;

        singlePageSlider.find(".sp-slider__slide").each(function (index) {
            $(this).attr("data-position", index);
        });

        singlePageSlider.owlCarousel({
            loop: false,
            items: 5,
            dots: false,
            nav: false,
            center: true,
        });

        this.initSliderPopup();

        $(".sp-slider__slide").on("click", function () {
            // if single shop page
            if ($(this).hasClass("sp-slider__shop")) {
                singlePageSlider.trigger(
                    "to.owl.carousel",
                    $(this).data("position")
                );

                const centerImgLarge = $(this).data("largeimg");
                const centerImgFull = $(this).data("fullimg");

                $(".sp-slider__img-big-wrapper a").css("display", "none");
                $(
                    ".sp-slider__img-big-wrapper a[href='" +
                        centerImgFull +
                        "']"
                ).css("display", "block");
            } else {
                // center carousel on clicked slide
                singlePageSlider.trigger(
                    "to.owl.carousel",
                    $(this).data("position")
                );

                // change big img above carousel
                const centerImgLarge = $(this).data("largeimg");
                const centerImgFull = $(this).data("fullimg");
                // if clicked slide is not active
                if (
                    $(".js--sp-slider__img-big-wrapper a:first-of-type").attr(
                        "href"
                    ) != centerImgFull
                ) {
                    // change big img
                    $(".sp-slider__img-big img").attr("src", centerImgLarge);
                    // add link current active img link to all links
                    $(".js--sp-slider__img-big-wrapper").append(
                        "<a href=" +
                            $(
                                ".js--sp-slider__img-big-wrapper a:first-of-type"
                            ).attr("href") +
                            ">"
                    );
                    // remove duplicate links
                    $(
                        ".js--sp-slider__img-big-wrapper a[href='" +
                            centerImgFull +
                            "']"
                    ).remove();
                    // change active img link
                    $(".js--sp-slider__img-big-wrapper a:first-of-type").attr(
                        "href",
                        centerImgFull
                    );

                    self.initSliderPopup();
                }
            }
        });
    }

    initSliderPopup() {
        $(".js--sp-slider__img-big-wrapper a").magnificPopup({
            type: "image",
            gallery: {
                enabled: true,
                tCounter: '<span class="mfp-counter">%curr% / %total%</span>', // markup for counter
            },
        });
    }

    initializeSinglePostSlider() {
        /**
         * single page related slider (news, advice)
         */

        // remove archive-list-item from slider container
        $(".js--related-sp__slider .archive-list-item").remove();

        $(".js--related-sp__slider").owlCarousel({
            dots: false,
            nav: true,
            mouseDrag: false,
            autoplay: true,
            autoplayTimeout: 2000,
            navText: [
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-left"></use></svg></span>',
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-rigth"></use></svg></span>',
            ],
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 1,
                    loop: $(".js--related-sp__slider .shop-tile").length > 1,
                },
                576: {
                    items: 2,
                    loop: $(".js--related-sp__slider .shop-tile").length > 2,
                },
                // breakpoint from 768 up
                992: {
                    items: 4,
                    loop: $(".js--related-sp__slider .shop-tile").length > 4,
                },
            },
        });

        $(".js--related-sp__slider-product").owlCarousel({
            dots: false,
            nav: true,
            mouseDrag: false,
            autoplay: true,
            autoplayTimeout: 2000,
            navText: [
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-left"></use></svg></span>',
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-rigth"></use></svg></span>',
            ],
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 1,
                    loop:
                        $(".js--related-sp__slider-product .shop-tile").length >
                        1,
                },
                576: {
                    items: 2,
                    loop:
                        $(".js--related-sp__slider-product .shop-tile").length >
                        2,
                },
                // breakpoint from 768 up
                992: {
                    items: 4,
                    loop:
                        $(".js--related-sp__slider-product .shop-tile").length >
                        4,
                },
            },
        });
    }

    initializeContestSlider() {
        /** Contest slider */
        $(".domarKonkurs .owl-carousel").owlCarousel({
            loop: true,
            margin: 0,
            nav: true,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                1000: {
                    items: 1,
                },
            },
        });

        /** Contest additional functions */
        $(".domarKonkurs .expand").on("click", function () {
            $(this).parent().find(".collapse").toggleClass("show");
            $(this).toggleClass("open");
        });

        /** Style checkboxes */
        $(".wpcf7-acceptance")
            .find(".wpcf7-list-item")
            .addClass("custom-control custom-checkbox");
        $(".wpcf7-acceptance").find("input").addClass("custom-control-input");
        $(".wpcf7-acceptance")
            .find(".wpcf7-list-item-label")
            .addClass("custom-control-label");
    }

    initializeWebinarSliders() {
        /**
         * webinars slider
         */
        $(".js-webinars-slider").owlCarousel({
            loop: true,
            margin: 10,
            dots: false,
            nav: true,
            navText: [
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-left"></use></svg></span>',
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-rigth"></use></svg></span>',
            ],
            autoplay: true,
            autoplayTimeout: 3000,
            responsive: {
                0: {
                    items: 1.5,
                    nav: false,
                },
                576: {
                    items: 2,
                    nav: false,
                },
                768: {
                    items: 3,
                    nav: false,
                },
                992: {
                    items: 4,
                    nav: false,
                },
            },
        });

        $(".js-webinars-partners-slider").owlCarousel({
            loop: true,
            margin: 10,
            dots: false,
            nav: false,
            items: 6,
            navText: [
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-left"></use></svg></span>',
                '<span><svg class="button-arrow"><use class="follow-icon" xlink:href="#arrow-rigth"></use></svg></span>',
            ],
            autoplay: true,
            autoplayTimeout: 3000,
            responsive: {
                0: {
                    items: 2,
                    nav: false,
                },
                576: {
                    items: 3,
                    nav: false,
                },
                768: {
                    items: 3,
                    nav: false,
                },
                992: {
                    items: 6,
                    nav: false,
                },
            },
        });
    }
}
