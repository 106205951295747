/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import $ from "jquery";
import magnificPopup from "magnific-popup";

export default class MagnificPopup {
    constructor() {
        /* magnificPopup in gallery */
        this.imgGalleries = [...document.querySelectorAll(".js--gallery")];

        /* magnificPopup in single img in f-c__content */
        this.contentLinks = [...document.querySelectorAll(".f-c__content a")];

        this.contentLinksWithImgs = this.contentLinks.length
            ? this.contentLinks.filter((link) => {
                  const img = link.querySelector("img");

                  return !!img;
              })
            : [];

        /* magnificPopup in single product or promotion >>> theme\Sliders.js */

        this.events();
    }

    events() {
        if (this.imgGalleries.length) {
            this.imgGalleries.forEach((gallery) =>
                this.initImgGallery(gallery)
            );
        }

        if (this.contentLinksWithImgs.length) {
            this.contentLinksWithImgs.forEach((img) =>
                this.initContentImg(img)
            );
        }
    }

    initImgGallery(gallery) {
        $(gallery).magnificPopup({
            delegate: ".js--gallery-item" /* the selector for gallery item */,
            type: "image",
            gallery: {
                enabled: true,
                tCounter:
                    '<span class="mfp-counter">%curr% / %total%</span>' /* markup for counter */,
            },
            image: {
                /* options for image content type */
                titleSrc: function (item) {
                    return item.img[0].alt;
                },
            },
        });
    }

    initContentImg(img) {
        $(img).magnificPopup({
            type: "image",
            image: {
                /* options for image content type */
                titleSrc: function (item) {
                    return item.img[0].alt;
                },
            },
        });
    }
}
