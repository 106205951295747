/* eslint-disable no-undef */
import { getScript } from "../../functions/helpers";

export default class FbLike {
    constructor() {
        this.FbCommentContainer = document.querySelector(".js--comments-fb");
        this.commentsInitialized = false;

        this.events();
    }

    events() {
        if (
            document.body.classList.contains("single-post") &&
            this.FbCommentContainer
        )
            this.startWithObserver(this.FbCommentContainer, {
                rootMargin: "0px 0px 0px 0px",
            });
    }

    startWithObserver(observedElement, observedElementOptions) {
        const current = this;
        const countersObserverOptions = observedElementOptions;
        const countersObserver = new IntersectionObserver(function (
            entries,
            observedElement
        ) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    current.loadFbCommentsOnPage();
                }
            });
        },
        countersObserverOptions);

        countersObserver.observe(observedElement);
    }

    loadFbCommentsOnPage() {
        if (this.commentsInitialized) return;

        const scriptUrl = `https://connect.facebook.net/${__jsVars.locale}/all.js#xfbml=1`;

        getScript(scriptUrl)
            .then(() => {
                FB.init({
                    status: true,
                    cookie: true,
                    xfbml: true,
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }
}
