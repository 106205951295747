export default class VirtualTour {
    constructor() {
        this.vrWrapper = document.querySelector(".js--vr-wrapper");
        this.vrWrapperLink = document.querySelector(".js--about-page-vr-link");

        if (this.vrWrapper) {
            this.events();
        }
    }

    events() {
        this.scrollOnPageLoad();

        if (this.vrWrapperLink) {
            this.vrWrapperLink.addEventListener(
                "click",
                this.scrollToVrTour.bind(this)
            );
        }
    }

    scrollOnPageLoad() {
        if (window.location.hash === "#wirtualny-spacer") {
            setTimeout(() => {
                this.scrollToVrTour();
            }, 500);
        }
    }

    scrollToVrTour(ev = null) {
        if (ev) ev.preventDefault();

        const vrPosition = this.vrWrapper.getBoundingClientRect().top;

        window.scrollTo({
            top: vrPosition - 50,
            behavior: "smooth",
        });
    }
}
