/* eslint-disable no-unused-vars */
/**
 * Import 3rd party packages
 */

/**
 * Import stylesheet
 */
import "../scss/theme.scss";

/**
 * Import modules / classes
 */
import Sliders from "./modules/theme/Sliders";
import CustomSelect from "./modules/theme/CustomSelect";
import LoadMorePosts from "./modules/theme/LoadMorePosts";
import LayoutSwitcher from "./modules/theme/LayoutSwitcher";
import RefreshPageFragments from "./modules/theme/RefreshPageFragments";
import OpenCategoryUrl from "./modules/theme/OpenCategoryUrl";
import Navigation from "./modules/theme/Navigation";
import Tabs from "./modules/theme/Tabs";
import ReadMoreBtn from "./modules/theme/ReadMoreBtn";
import VirtualTour from "./modules/theme/VirtualTour";
import MagnificPopup from "./modules/theme/MagnificPopup";
import AjaxSearch from "./modules/theme/AjaxSearch";
import TaxonomyAdmin from "./modules/theme/TaxonomyAdmin";
import FbLike from "./modules/theme/FbLike";
import Favourites from "./modules/theme/Favourites";
import HoursPopup from "./modules/theme/HoursPopup";
import Popup from "./modules/theme/Popup";
import Newsletter from "./modules/theme/Newsletter";
import NewsletterSignup from "./modules/theme/NewsletterSignup";

/**
 * Instantiate a new object using imported modules/classes
 */
document.addEventListener("DOMContentLoaded", () => {
    const mySliders = new Sliders();
    const myCustomSelect = new CustomSelect();
    const myLoadMorePosts = new LoadMorePosts();
    const myLayoutSwitcher = new LayoutSwitcher();
    const myRefreshPageFragments = new RefreshPageFragments();
    const myOpenCategoryUrl = new OpenCategoryUrl();
    const myNavigation = new Navigation();
    const myTabs = new Tabs();
    const myReadMoreBtn = new ReadMoreBtn();
    const myVirtualTour = new VirtualTour();
    const myMagnificPopup = new MagnificPopup();
    const myAjaxSearch = new AjaxSearch();
    const myTaxonomyAdmin = new TaxonomyAdmin();
    const myFbLike = new FbLike();
    const myFavourties = new Favourites();
    const myHoursPopup = new HoursPopup();
    const myPopup = new Popup();
    const myNewsletter = new Newsletter();
    const myNewsletterSignup = new NewsletterSignup();
});
