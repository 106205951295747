import { isVisible } from "../../functions/helpers";

export default class TaxonomyAdmin {
    constructor() {
        this.taxonomyListCollapse = [
            ...document.querySelectorAll(".taxonomy-list__collapse"),
        ];

        if (this.taxonomyListCollapse.length) {
            this.events();
        }
    }

    events() {
        if (document.body.classList.contains("wp-admin")) {
            this.taxonomyListCollapse.forEach((itemCollapse) =>
                itemCollapse.addEventListener(
                    "click",
                    this.toggleListCollapseBtn.bind(this, itemCollapse)
                )
            );
        }
    }

    toggleListCollapseBtn(elem, ev) {
        ev.preventDefault();

        if (!elem) return;
        const parentItem = elem.closest(".taxonomy-list-item");

        if (!parentItem) return;
        const listToToggle = parentItem.querySelector(
            ".taxonomy-list-children"
        );

        if (!listToToggle) return;
        listToToggle.classList.toggle("show");
        elem.innerText = listToToggle.classList.contains("show") ? "-" : "+";

        if (isVisible(listToToggle)) {
            const checkbox = parentItem.querySelector('input[type="checkbox"]');

            if (checkbox) checkbox.checked = true;
        }
    }
}
