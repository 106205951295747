/* eslint-disable no-undef */
export default class AjaxSearch {
    constructor() {
        this.searchTrigger = document.querySelector(".js--menu-search");
        this.searchContainer = document.querySelector(".js--search-container");
        this.searchCloseBtn = document.querySelector(".js--search-close");
        this.searchInput = document.querySelector(".js--ajax-search");
        this.searchResults = document.querySelector(".js--search-results");
        this.searchLinks = [
            ...document.querySelectorAll(".js--sr__aside-link"),
        ];
        this.minSearchQueryLength = 2;
        this.searchTimeoutTime = 500;

        this.events();
    }

    events() {
        if (this.searchLinks.length) this.initSearchLinks();

        if (this.searchTrigger && this.searchCloseBtn) {
            this.searchTrigger.addEventListener(
                "click",
                this.showSearchModal.bind(this)
            );

            this.searchCloseBtn.addEventListener(
                "click",
                this.hideSearchModal.bind(this)
            );
        }

        if (this.searchInput) {
            this.searchInput.addEventListener(
                "keyup",
                this.handleSearchInput.bind(this)
            );
        }
    }

    initSearchLinks() {
        this.searchLinks.forEach((link) => {
            const linkHref = link.getAttribute("href");

            if (window.location.href.includes(linkHref)) {
                link.classList.add("--active");
            }
        });
    }

    showSearchModal(ev) {
        if (!this.searchContainer || !this.searchInput) return;

        this.searchContainer.classList.add("open");
        document.body.classList.add("overflow-hidden");
        this.searchInput.focus();
    }

    hideSearchModal(ev) {
        if (!this.searchContainer) return;

        this.searchContainer.classList.remove("open");
        document.body.classList.remove("overflow-hidden");
    }

    handleSearchInput(ev) {
        const input = ev.currentTarget;

        if (!input) return;
        const s = input.value;

        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(() => {
            if (s.length > this.minSearchQueryLength) {
                this.triggerSearch(s);
            } else {
                if (this.searchResults)
                    this.searchResults.innerHTML = `<p>Wyszukiwana fraza powinna mieć co najmniej 3 znaki</p>`;
            }
        }, this.searchTimeoutTime);
    }

    triggerSearch(s) {
        const postData = {
            s,
            action: "ic-search",
        };

        fetch(__jsVars.url.ajax, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: new URLSearchParams(postData),
        })
            .then((resp) => resp.json())
            .then((res) => {
                if (res.success && res.data.length > 0) {
                    this.searchResults.innerHTML = "";

                    /* append search results category */
                    res.data.forEach((el) => {
                        this.searchResults.insertAdjacentHTML(
                            "beforeend",
                            `
                            <a href="${el.group_url}" class="search__single-result search__single-result--category">
                            <span class="search__name"><strong>${el.group_name}</strong></span>
                            <span class="search__count"> Zobacz wszystkie <strong>(${el.found_posts})</strong></span>
                            </a>
                            `
                        );

                        /* append search results for each category */
                        el.data.forEach((ell) => {
                            if (ell.thumbnail) {
                                this.searchResults.insertAdjacentHTML(
                                    "beforeend",
                                    `
                                    <a href="${ell.url}" class="search__single-result">
                                    <span class="search__thumbnail" style="background-image: url('${ell.thumbnail}');"></span>
                                    <span class="search__name">${ell.title}</span>
                                    </a>
                                    `
                                );
                            } else {
                                this.searchResults.insertAdjacentHTML(
                                    "beforeend",
                                    `
                                    <a href="${ell.url}" class="search__single-result">
                                    <span class="search__thumbnail"></span>
                                    <span class="search__name">${ell.title}</span>
                                    </a>
                                    `
                                );
                            }
                        });
                    });
                } else {
                    this.searchResults.innerHTML = `<p>Brak wyników wyszukiwania</p>`;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
}
