export default class Newsletter {
    constructor() {
        this.newsletterForm = document.querySelector(
            ".js--footer-newsletter-form"
        );

        /* first view */
        this.email = document.querySelector(
            ".footer-newsletter__view.first input[type=email]"
        );
        this.termsCheckbox = document.querySelector(
            ".footer-newsletter__view.first input[type=checkbox]"
        );
        this.firstButton = document.querySelector(
            ".footer-newsletter__view.first button"
        );

        /* second view */
        this.name = document.querySelector(
            ".footer-newsletter__view.second input[type=text]"
        );

        /* checkboxes list in second view */
        this.listCheckboxes = [
            ...document.querySelectorAll(
                ".footer-newsletter__view.second input[type=checkbox]"
            ),
        ];
        this.secondButton = document.querySelector(
            ".footer-newsletter__view.second button"
        );

        this.events();
    }

    events() {
        if (this.termsCheckbox && this.email) {
            this.termsCheckbox.addEventListener(
                "change",
                this.validateFormOne.bind(this)
            );

            this.email.addEventListener(
                "keyup",
                this.validateFormOne.bind(this)
            );

            this.email.addEventListener(
                "blur",
                this.validateFormOne.bind(this)
            );
        }

        if (this.listCheckboxes.length && this.name) {
            this.listCheckboxes.forEach((checkbox) =>
                checkbox.addEventListener(
                    "change",
                    this.validateFormTwo.bind(this)
                )
            );

            this.name.addEventListener(
                "keyup",
                this.validateFormTwo.bind(this)
            );

            this.name.addEventListener("blur", this.validateFormTwo.bind(this));
        }

        /* if first view is valid, show second view */
        if (this.firstButton) {
            this.firstButton.addEventListener(
                "click",
                this.showSecondStep.bind(this)
            );
        }
    }

    /* validates first part of form */
    validateFormOne(ev) {
        // eslint-disable-next-line prefer-regex-literals
        const patt = new RegExp(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if (!this.firstButton) return;

        if (patt.test(this.email.value) && this.termsCheckbox.checked) {
            this.firstButton.classList.remove("disabled");
            this.firstButton.removeAttribute("disabled");
        } else {
            this.firstButton.classList.add("disabled");
            this.firstButton.setAttribute("disabled", "true");
        }
    }

    /* validates second part of form */
    validateFormTwo(ev) {
        const checkedCheckboxes = this.listCheckboxes.filter(
            (checkbox) => checkbox.checked
        );

        if (!this.secondButton) return;

        if (this.name.value.length > 1 && checkedCheckboxes.length) {
            this.secondButton.classList.remove("disabled");
            this.secondButton.removeAttribute("disabled");
        } else {
            this.secondButton.classList.add("disabled");
            this.secondButton.setAttribute("disabled", "true");
        }
    }

    showSecondStep(ev) {
        ev.preventDefault();

        this.newsletterForm.classList.add("second-step");
    }
}
