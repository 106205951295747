export default class ReadMoreBtn {
    constructor() {
        this.readmoreBtn = document.querySelector(".js--readmore-btn");
        this.readmoreContent = document.querySelector(".js--readmore-content");

        if (this.readmoreBtn && this.readmoreContent) {
            this.events();
        }
    }

    events() {
        this.readmoreBtn.addEventListener(
            "click",
            this.toggleContent.bind(this)
        );
    }

    toggleContent(ev) {
        ev.preventDefault();

        const btnAriaStatus = this.readmoreBtn.getAttribute("aria-expanded");

        if (btnAriaStatus === "false") {
            this.readmoreContent.classList.add("is-expanding");
            this.readmoreBtn.setAttribute("aria-expanded", "true");
            this.readmoreBtn.innerText = this.readmoreBtn.dataset.showLess;
            this.readmoreBtn.style.pointerEvents = "none";

            this.readmoreContent.addEventListener("animationend", () => {
                this.readmoreContent.setAttribute("aria-hidden", "false");
                this.readmoreContent.classList.remove("is-expanding");
                this.readmoreBtn.removeAttribute("style");
            });
        } else {
            this.readmoreContent.classList.add("is-collapsing");
            this.readmoreBtn.setAttribute("aria-expanded", "false");
            this.readmoreBtn.innerText = this.readmoreBtn.dataset.showMore;
            this.readmoreBtn.style.pointerEvents = "none";

            this.readmoreContent.addEventListener("animationend", () => {
                this.readmoreContent.setAttribute("aria-hidden", "true");
                this.readmoreContent.classList.remove("is-collapsing");
                this.readmoreBtn.removeAttribute("style");
            });
        }
    }
}
